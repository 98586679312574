import { EnClipType, ISmartListImage } from '@medlogic/shared/ui/ui-smart-list';
import { IProcessState, IListItem, IAppState, IModule, ISmartList, LogService, GlobalService } from '@medlogic/shared/shared-interfaces';
import { filterShowMobileAndModulesMenu, ItemSearchPipe } from '@medlogic/shared/utils';

const log = new LogService();
const glb = new GlobalService();

export const selectProcess = (state: IProcessState) => state?.selectedProcess || undefined;
export const selectProcessAtividadeNo = (state: IProcessState) => +state?.selectedProcess?.ObjInfo?.AtividadeNo;
export const selectProcessProcessoNo = (state: IProcessState) => +state?.selectedProcess?.ObjInfo?.ProcessoNo;

export const selectAllProcesses = (state: IProcessState) => state?.allProcesses?.length > 0 ? state.allProcesses : undefined;
export const selectMenuProcesses = (state: IProcessState) => {
  const menuProcesses = state?.allProcesses.filter(f => filterShowMobileAndModulesMenu(f.ObjProcess));
  return menuProcesses?.length > 0 ? menuProcesses : undefined;
}

export const selectProcessError = (state: IProcessState) => state?.error;

export const selectProcessesAsISmartListImages = (state: any) => {
  let items = state?.process?.allProcesses?.map(toISmartListImage);
  items = filterBySearch(items, state?.appAddhere?.search);
  return [
    { title: 'Ferramentas', items } as ISmartList<ISmartListImage>
  ];
};

const filterBySearch = (items: ISmartListImage<IModule>[], keyword: string) => {
  return new ItemSearchPipe(log, glb).transform(items, keyword);
}

const toISmartListImage = ((process: IModule) => {
  return ({
    id: process?.ObjInfo?.ProcessoNo,
    imgUrl: process?.UrlIcon,
    enClipType: EnClipType.square,
    topLeft: process?.Label,
    bottomLeft: '',
    iconName: null,
    iconColor: null,
    obj: process
  } as ISmartListImage<IModule>);
});

/** Operador processalizado que converte os processos em módulos. */
const toIListItem = ((process: IModule) => {
  return ({
    id: process?.ObjInfo?.ProcessoNo,
    identification1: process?.ObjInfo?.ProcessoNo,
    identification2: process?.Label,
    topLeft: process?.Label,
    bottomLeft: process?.LabelMiniInfo,
    topRight: '',
    bottomRight: '',
    color: 'green',
    imgUrl: process?.UrlIcon,
    obj: process
  } as IListItem<IModule>);
})

export const selectProcessesAsIListItems = (state: IAppState) => state?.process?.allProcesses?.map(toIListItem);


