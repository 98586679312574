<ng-container *ngLet="(enViewMode$ | async) as enViewMode">

    <mat-sidenav-container *ngIf="enViewMode !== ENVIEWMODE.print" class="sidenav-container" autosize>

        <mat-sidenav #drawer class="sidenav no-print" fixedInViewport
            [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="'over'">

            <mat-toolbar class="menuTitle">Menu</mat-toolbar>

            <hr>

            <mat-nav-list class="menuNavigation">

                <ng-container *ngFor="let menuItem of menuItems">

                    <a mat-list-item *ngIf="!(menuItem?.isPersonDependent)" [routerLink]="menuItem?.link"
                        routerLinkActive="['active']" [ngClass]="{'active': clickedItem === menuItem?.name }"
                        (click)="drawer?.toggle(); onMenuSelection(menuItem)">
                        <i class="material-icons">{{ menuItem?.icon }}</i>{{ menuItem?.name }}
                    </a>

                </ng-container>

                <hr>

                <a mat-list-item *ngLet="isMobile$ | async as isMobile">
                    <mat-slide-toggle [checked]="!isMobile" (change)="onDesktopChange($event, drawer)"
                        [(ngModel)]="isDesktop">
                        Versão Desktop
                    </mat-slide-toggle>
                </a>

                <a mat-list-item *ngLet="enTheme$ | async as enTheme">
                    <mat-slide-toggle [checked]="enTheme === ENTHEME.black" (change)="onDarkModeChange($event, drawer)"
                        [(ngModel)]="isDark">
                        Modo Escuro
                    </mat-slide-toggle>
                </a>

                <a mat-list-item (click)="onFullScreenClick($event, drawer)">
                    <i class="material-icons">fullscreen</i>Modo tela cheia
                </a>

                <a mat-list-item (click)="onCacheCleanClick($event, drawer)">
                    <i class="material-icons">cached</i>Limpar o cache
                </a>

            </mat-nav-list>


        </mat-sidenav>

        <mat-sidenav-content [class.black]="(enTheme$ | async) === ENTHEME.black"
            [class.is-mobile]="(isMobile$ | async)">

            <ng-container *ngIf="enViewMode !== ENVIEWMODE.fullscreen">

                <mat-menu #menu="matMenu">

                    <button mat-menu-item *ngIf="(userLogin$ | async) as userLogin">
                        <mat-icon>face</mat-icon>
                        <span>{{ userLogin }}</span>
                    </button>

                    <button mat-menu-item (click)="onExitClick($event)">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Sair</span>
                    </button>

                </mat-menu>

                <mat-toolbar class="no-print">

                    <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer?.toggle()">
                        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                    </button>

                    <div class="appCurrentScreen">
                        <span *ngIf="(isLoading$ | async) === (false || null || undefined)" [@fadeAnimation]="'in'">{{
                            title$ | async | uppercase
                            }}</span>
                    </div>

                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Opções">
                        <mat-icon>more_vert</mat-icon>
                    </button>

                </mat-toolbar>

            </ng-container>

            <main class="parentContainer" [@fadeAnimation]="outletRef?.isActivated ? outletRef?.activatedRoute : ''">
                <router-outlet #outletRef="outlet"></router-outlet>
            </main>

            <footer *ngIf="(showFooter$ | async)" class="no-print">
                <!-- <app-bottom-menu [selectedOption]="selectedOption"></app-bottom-menu> -->
            </footer>

        </mat-sidenav-content>

    </mat-sidenav-container>


    <!-- Layout alternativo, todo limpo, para impressão -->
    <router-outlet #outletRef="outlet" *ngIf="enViewMode === ENVIEWMODE.print"></router-outlet>


</ng-container>




<div class="overlay no-print" *ngIf="(isLoading$ | async) as loading">
    <ui-loading class="spinner"></ui-loading>
</div>