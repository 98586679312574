import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IPractitionerState } from './practitioner-state';
import * as fromPractitioner from './practitioner.reducer';

export const selectPractitionerState = createFeatureSelector<IPractitionerState>(fromPractitioner.practitionersFeatureKey);
export const selectPractitionerAll = createSelector(selectPractitionerState, fromPractitioner.selectAll);

export const selectPractitionerAllActive = createSelector(
  selectPractitionerAll,
  practitioners => practitioners
    .filter(practitioner => practitioner.active && practitioner.drt)
    .sort((a, b) => a?.practitionerName?.localeCompare(b?.practitionerName))
);

export const selectedPractitioner = createSelector(selectPractitionerState, state => {
  return state?.selectedId ? state?.entities[state?.selectedId] : null;
});

export const isPatientLoading = createSelector(
  selectPractitionerAll,
  practitioners => practitioners?.length <= 0,
);

export const selectedPractitionerIdentifier = createSelector(selectPractitionerState, state => {
  return (state?.selectedId || 0) > 0 ? state?.selectedId : null;
});
