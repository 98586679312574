import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { IDialogData } from '@medlogic/shared/shared-interfaces';
import { IAppMedlogicFhirState } from '@medlogic/fhir';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectedPatientId } from '@medlogic/medlogic/medlogic-state';

@Component({
  selector: 'ui-dialog-alert',
  templateUrl: './ui-dialog-alert.component.html',
  styleUrls: ['./ui-dialog-alert.component.css']
})
export class UiDialogAlertComponent implements OnInit {

  selectedPatientId$: Observable<number> = this.store.pipe(select(selectedPatientId));

  // tslint:disable: variable-name
  private _title: string;
  public get title(): string {
    return this._title;
  }
  public set title(v: string) {
    this._title = v;
  }

  private _btnOK: string;
  public get btnOK(): string {
    return this._btnOK;
  }
  public set btnOK(v: string) {
    this._btnOK = v;
  }

  private _behaviour: boolean;
  public get behaviour(): boolean {
    return this._behaviour;
  }
  public set behaviour(v: boolean) {
    this._behaviour = v;
  }

  private _icon: string;
  public get icon(): string {
    return this._icon;
  }
  public set icon(v: string) {
    this._icon = v;
  }

  behaviours = [
    { display: 'residente-normal', description: "Normal" },
    { display: 'residente-alteracao-leve', description: "Alteração Leve" },
    { display: 'residente-agressivo', description: "Agressivo" }
  ];

  message = '';

  constructor(
    public dialogRef: MatDialogRef<UiDialogAlertComponent>,
    private store: Store<IAppMedlogicFhirState>,
    @Inject(MAT_DIALOG_DATA) private data: IDialogData) {
    this.title = data?.title || '';
    this.btnOK = data?.btnOk || 'OK';
    this.behaviour = data?.behaviour || false;
    this.message = data?.messageHtml || '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (this.behaviour) {
      if (this.message.includes('Residente Agressivo')) {
        this.behaviours.splice(2, 1);
      }
      else if (this.message.includes('Residente Alteração Leve')) {
        this.behaviours.splice(1, 1);
      } else {
        this.behaviours.splice(0, 1);
      }
    }
  }

  changeData() {
  }

  changeBehaviour(iconDisplay: string) {
    this.dialogRef.close(iconDisplay);
  }


}
