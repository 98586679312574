import { StateLogModule } from '@medlogic/shared/state-log';
import { StateProcessModule } from '@medlogic/shared/shared-state-process';
import { MedlogicNavigationModule } from '@medlogic/medlogic/medlogic-navigation';
import { SharedUtilsModule } from '@medlogic/shared/utils';
import { MedlogicStateModule } from '@medlogic/medlogic/medlogic-state';
import { NgrxRouterModule } from '@medlogic/shared/ngrx-router';
import { SharedInterfacesNgModule, initConfig, ConfigJsonService } from '@medlogic/shared/shared-interfaces';
import { UiLoadingModule } from '@medlogic/shared/ui/ui-loading';
import { MaterialModule } from '@medlogic/shared/material';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { AppSistemaMedlogicRoutingModule } from './app-sistema-medlogic-routing.module';
import { AppSistemaMedlogicComponent } from './app-sistema-medlogic.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, DateAdapter } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ModelComponentService, DataAccessModule } from '@medlogic/shared/shared-data-access';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgLetModule } from '@ngrx-utils/store';

const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  AppSistemaMedlogicRoutingModule,
  MaterialModule,
  HttpClientModule,
  SharedInterfacesNgModule,
  UiLoadingModule,
  DataAccessModule,
  SharedUtilsModule,
  MedlogicNavigationModule,
  NgLetModule
];

const COMPONENTS = [
  AppSistemaMedlogicComponent
];

const STATE_MODULES = [
  StoreModule.forRoot({}),
  EffectsModule.forRoot([]),
  NgrxRouterModule,
  MedlogicStateModule,
  StateProcessModule, // TODO: State Process deveria ser migrado para MedLogicStateModule?
  StateLogModule,
  !environment.production
    ? StoreDevtoolsModule.instrument({
      maxAge: 10,
      name: 'MEDLOGIC',
      logOnly: environment.production
    })
    : [],
];

@NgModule({
  declarations: [
    COMPONENTS
  ],
  imports: [
    MODULES, STATE_MODULES
  ],
  providers: [
    ModelComponentService,
    ConfigJsonService,
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'pt' },
    { provide: LOCALE_ID, useValue: 'pt' }, // replace "en-US" with your locale
    {
      // Para carregar as configurações do arquivo antes que as chamadas sejam realizadas
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [ConfigJsonService],
      multi: true,
    },
  ],
  bootstrap: [AppSistemaMedlogicComponent]
})
export class AppSistemaMedlogicModule { }
