import { metaReducers } from './+state/process.metareducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProcessEffects } from './+state/process.effects';
import { processReducer } from './+state/process.reducer';


const MODULES = [
  CommonModule
];

const STATE_MODULES = [
  StoreModule.forFeature('process', processReducer, { metaReducers }),
  EffectsModule.forFeature([ProcessEffects]),
];

@NgModule({
  imports: [MODULES, STATE_MODULES],
})
export class StateProcessModule { }
