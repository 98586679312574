import { createAction, props } from '@ngrx/store';
import { IModule } from '@medlogic/shared/shared-interfaces';

// TODO: Each model need to have its specific Action, Reducer, Effects.

export enum ProcessActionTypes {
  LoadProcess = '[Page Process Component] Load Process',
  LoadProcessMenu = '[Process Component] Load Process Menu',
  LoadProcessHTML5 = '[SISTEMA Page Process Component] Load Process HTML5',
  FetchSuccessProcess = '[Process API] Fetch Success Process',
  FetchErrorProcess = '[Process API] Fetch Error Process',
  // SetProcessId = '[Process Component] Set Process Id',
  SetProcessAndLoadGeneric = '[ProcessListView Component] Set Process and Load Generic',
  SetProcessAndLoadGenericByPerson = '[Process Component] Set Process and Load Generic By Person',
  SetProcess = '[ProcessListView Component] Set Process',
  ClearProcess = '[StateManagement Service] Clear Process',

}

export const loadProcesses = createAction(ProcessActionTypes.LoadProcess);
export const loadProcessesMenu = createAction(ProcessActionTypes.LoadProcessMenu);
export const loadProcessesHTML5 = createAction(ProcessActionTypes.LoadProcessHTML5);
export const setProcessAndLoadGeneric = createAction(ProcessActionTypes.SetProcessAndLoadGeneric, props<{ selectedProcess: IModule }>());
export const setProcessAndLoadGenericByPerson = createAction(ProcessActionTypes.SetProcessAndLoadGenericByPerson, props<{ selectedProcess: IModule }>());
export const setProcess = createAction(ProcessActionTypes.SetProcess, props<{ selectedProcess: IModule }>());
export const clearProcess = createAction(ProcessActionTypes.ClearProcess);
// export const setProcessId = createAction(ProcessActionTypes.SetProcessId, props<{ processId: number }>());

// tslint:disable: max-line-length
export const fetchSuccessProcess = createAction(ProcessActionTypes.FetchSuccessProcess, props<{ allProcesses: IModule[] }>());
// export const selectProcess = createAction(ProcessActionTypes.SelectProcess, props<{ selectedProcess: IModule }>());
export const fetchErrorProcess = createAction(ProcessActionTypes.FetchErrorProcess, props<{ allProcesses: IModule[] }>());
