import { IActivity, EnActivityStatus, IActivityIcon, ILogin, UnsubscribeOnDestroyAdapter, AppLogService } from '@medlogic/shared/shared-interfaces';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppMedlogicPwaCuidadoState, selectSelectedLogin } from '@medlogic/medlogic-pwa/medlogic-pwa-cuidado-full';
import { Observable, take } from 'rxjs';

@Component({
  selector: 'ml-ui-care-plan-activity',
  templateUrl: './ui-care-plan-activity.component.html',
  styleUrls: ['./ui-care-plan-activity.component.css']
})
export class UiCarePlanActivityComponent extends UnsubscribeOnDestroyAdapter implements OnInit {

  @Input() activity: IActivity;
  @Input() activityIcons: IActivityIcon[] | null = [];
  @Output() activityClick = new EventEmitter<IActivity>();

  icons: string[] = [];
  currentDate: string | Date;
  selectedPractitioner: ILogin;
  $selectedPractitioner: Observable<ILogin>;

  ENACTIVITYSTATUS = EnActivityStatus;

  constructor(
    private log: AppLogService,
    private store: Store<AppMedlogicPwaCuidadoState>
  ) {
    super();
  }

  ngOnInit() {
    try {
      this.currentDate = this.activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start ?? this.activity.currentProgress.time;
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }

    this.subs.sink = this.store
      .pipe(
        select(selectSelectedLogin),
        take(1)
      )
      .subscribe(usuario => {
        this.selectedPractitioner = usuario;
      });
  }

  onActivityClick(activity: IActivity): void {
    try {
      this.activityClick.emit(activity);
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'onActivityClick', error.message);
    }
  }

  isNightShift(activity) {
    const start = new Date(activity?.currentActivityDetail?.Scheduled?.ScheduledPeriod?.start);
    return start.getHours() >= 20 || start.getHours() < 7;
  }

  translateStatus(activity: any) {
    const status = activity?.status;
    try {
      switch (status) {
        case "done":
          return "Atividade completa";
        case "todo":
          return "Atividade programada";
        case "warn":
          return "Período de tolerância";
        case "pending":
          if (!this.isNightShift(activity)) return "Atividade atrasada";
          return "Atividade programada";
        case "late":
          return "Atividade completa fora do período de tolerância";
        case "extra":
          return "Atividade não programada"
        default:
          break;
      }
    } catch (error: any) {
      this.log.Registrar(this.constructor.name, 'translateStatus', error.message);
    }
  }
}
