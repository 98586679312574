export interface IPayload {
    customerId: number;
    dtCadastro: Date;
    exp: number;
    iat: number;
    Ip: string;
    isAdm: boolean;
    LoginUnifiedNo: number;
    ModuloNo: number;
    roles: string[];
    userName: string;
    UsuarioNo: number;
    UsuarioToken: string;
    email: string;
}
