<div
  class="activity-container"
  [class.hasCheckin]="activity?.currentProgress?.time"
  [class.done]="activity?.status == ENACTIVITYSTATUS.done"
  (click)="onActivityClick(activity)"
>
  <div
    class="clm-1"
    [class.done]="
      activity?.status == ENACTIVITYSTATUS.done ||
      (activity?.status == ENACTIVITYSTATUS.pending && isNightShift(activity))
    "
    [class.late]="activity?.status == ENACTIVITYSTATUS.late"
    [class.pending]="
      activity?.status == ENACTIVITYSTATUS.pending && !isNightShift(activity)
    "
    [class.todo]="activity?.status == ENACTIVITYSTATUS.todo"
    [class.extra]="activity?.status == ENACTIVITYSTATUS.extra"
    [class.warn]="activity?.status == ENACTIVITYSTATUS.warn"
  ></div>

  <div class="clm-2">
    <span>{{ currentDate | date: 'HH:mm' }}</span>
    <div class="v-divider"></div>
  </div>

  <div class="clm-3">
    <div>
      <span>{{ activity?.activityName }}</span>
    </div>

    <div class="v-divider"></div>
  </div>

  <div class="clm-4">
    <span *ngIf="activity?.status">{{ translateStatus(activity) }}</span>
    <span *ngIf="activity?.currentActivityDetail?.comments">{{
      'Observações: ' + activity?.currentActivityDetail?.comments
    }}</span>
  </div>
</div>
