import { ActionReducer, MetaReducer } from '@ngrx/store';
import { utilLocalStorageReducer } from '@medlogic/shared/utils';
import { ProcessActionTypes } from './process.actions';
import { initialState } from './process.reducer';

const KEY = 'MEDLOGIC_PROCESS_STATE';

// Includes the action types that must be persisted.
const PERSISTED_ACTIONS: string[] = [
  ProcessActionTypes.LoadProcess,
  ProcessActionTypes.FetchSuccessProcess,
  ProcessActionTypes.SetProcess,
  ProcessActionTypes.SetProcessAndLoadGeneric,
  ProcessActionTypes.SetProcessAndLoadGenericByPerson,
];

export function localStorageReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return utilLocalStorageReducer(reducer, PERSISTED_ACTIONS, KEY, initialState);
}

export const metaReducers: MetaReducer<any>[] = [localStorageReducer];
