<ng-container *ngrxLet="activityMode$ as activityMode">
  <div
    class="flex-vertical"
    [class.hasBottomBar]="activityMode == ENACTIVITYMODE.Extra"
  >
    <ml-ui-care-plan-header
      (menuItemClick)="onMenuItemClick($event)"
    ></ml-ui-care-plan-header>

    <ml-ui-care-plan-general-indicator
      [patientsIcons]="patientIcons$ | async"
    ></ml-ui-care-plan-general-indicator>

    <div [ngSwitch]="activityMode">
      <div *ngSwitchCase="ENACTIVITYMODE.Extra">
        <ml-ui-care-plan-activities
          [activityModeTitle]="activityModeTitle$ | async"
          [activitiesByShift]="
            activitiesExtraByShift$ | async | shift: (selectedShift$ | async)
          "
          (activityClick)="onActivityClick($event)"
        >
        </ml-ui-care-plan-activities>
      </div>

      <div *ngSwitchDefault>
        <ml-ui-care-plan-activities
          [activityModeTitle]="activityModeTitle$ | async"
          [activitiesByShift]="
            activitiesByShift$ | async | shift: (selectedShift$ | async)
          "
          (activityClick)="onActivityClick($event)"
        >
        </ml-ui-care-plan-activities>
      </div>
    </div>
  </div>

  <ml-ui-care-plan-footer
    [showAdd]="true"
    [showConfirm]="false"
    (actionClick)="onActionClick($event)"
  ></ml-ui-care-plan-footer>
</ng-container>

<ng-template #loading>
  <span>Carregando...</span>
</ng-template>

<ng-template #empty>
  <h2>Nenhum registro encontrado.</h2>
</ng-template>
