import { MedlogicAuthChildGuardService } from '@medlogic/medlogic/medlogic-navigation';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'exam',
    canActivateChild: [MedlogicAuthChildGuardService],
    loadChildren: () =>
      import('@medlogic/medlogic/pages/medlogic-page-exam')
        .then(
          (module) => module.MedlogicPagesMedlogicPageExamModule
        ),
  },
  { path: '', redirectTo: 'exam', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: false,
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppSistemaMedlogicRoutingModule { }
