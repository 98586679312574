import { ConfigStateService } from '@medlogic/shared/state-config';
import { Control } from '../../model/control';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LogService, ConfigJsonService, IAtividadeComponenteDAL, EnTheme } from '@medlogic/shared/shared-interfaces';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { LibService } from '../../service/lib.service';
import { CalculadoraService } from '../../service/calculadora.service';
import { CalculadoraConditionService } from '../../service/calculadora-condition.service';
import { MatDialog } from '@angular/material/dialog';
import { MsgPtBR } from '@medlogic/shared/shared-interfaces';
import { IBubble } from '../../interface/ibubble';
import { EnBubbleEvent } from '../../enum/en-bubble-event.enum';
import { EnFileUploadMode } from '../../enum/EnFileUploadMode';
import { FileUploadService } from '../../service/file-upload.service';
import { FormGroup, AbstractControl, ValidatorFn } from '@angular/forms';
import { IVariable } from '../../interface/ivariable';

@Component({
  selector: 'lib-ctr-image-player',
  templateUrl: './ctr-image-player.component.html',
  styleUrls: ['./ctr-image-player.component.css'],
  host: { '(window:eventImgReturn)': 'eventImgReturn($event)' }
})
export class CtrImagePlayerComponent extends Control implements OnInit {

  @Input() ctrl: IAtividadeComponenteDAL;
  @Input() formGroup = new FormGroup({});
  @Input() isLoading = true;
  @Input() isMobile: boolean;
  @Input() enTheme = EnTheme.default;

  @Output() onChangeNotify = new EventEmitter<IVariable>();
  /* Evento para permitir que os filhos, netos, etc, se comuniquem com todos os pais até a AtividadeView */
  @Output() eventBubble = new EventEmitter<IBubble>();

  [x: string]: any;
  imgFileText = 'Selecionar foto';
  isEditable = true;
  fileUploadMode = EnFileUploadMode.single;
  label: string;
  extensions = ['jpg', 'jpeg', 'gif', 'png'];
  FILEUPLOAD_METHOD = 'FILEUPLOAD(';
  imgIdoso = ""
  imageMissing(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      try {
        const isValid = !this.global.isNullOrEmpty(control?.value) && control?.value !== 'Foto';
        return !isValid ? { imageMissing: { value: control?.value } } : null;
      } catch (error) {
        this.log.Registrar(this.constructor.name, 'imageMissing', error.message);
      }
      return null;
    };
  }

  constructor(
    log: LogService,
    global: GlobalService,
    lib: LibService,
    cnf: ConfigStateService,
    cnfJson: ConfigJsonService,
    calc: CalculadoraService,
    calcCond: CalculadoraConditionService,
    msg: MsgPtBR,
    protected matDialog: MatDialog,
    protected fileUploadSrv: FileUploadService
  ) {
    super(log, global, lib, cnf, cnfJson, calc, calcCond, matDialog, msg);
  }

  ngOnInit(): void {
    try {
      this.isEditable = this.ctrl?.IsEnable;
      this.configLabelAndMode(this.ctrl);
      this.setCustomValidators(this.ctrl);
      localStorage.setItem("imgUpload", this.ctrl?.Rotulo);
      this.imgIdoso = this.ctrl.Rotulo
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'ngOnInit', error.message);
    }
  }

  private setCustomValidators(ctrl: IAtividadeComponenteDAL): void {
    try {
      const control = this.getFormControlFrom(ctrl);
      control.setValidators(this.imageMissing());
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'setCustomValidators', error.message);
    }
  }

  private configLabelAndMode(ctrl: IAtividadeComponenteDAL): void {
    try {
      if (this.isMethod(ctrl?.ValorDefault)) {
        this.fileUploadMode = EnFileUploadMode.document;
        const regex = /['"](.*?)['"]/gm;
        const match = regex.exec(ctrl?.ValorDefault);
        this.label = match ? match[1] : '';
      } else {
        this.label = ctrl?.ValorDefault;
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'configLabelAndMode', error.message);
    }
  }

  protected isMethod(label: string): boolean {
    try {
      return label?.includes(this.FILEUPLOAD_METHOD);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isMethod', error.message);
    }
    return false;
  }

  /* Evento acionado quando um javascript externo chama esse evento personalizado, nesse caso,
  * para retornar a url de uma imagem recém-inserida (webcam/file).
  * O evento poderá ser acionado: window.dispatchEvent(new Event('eventImgReturn'));
  */
  eventImgReturn(filePath: any): void {
    try {
      this.imgIdoso = localStorage.getItem("imgUpload");
      const ctrl = this.formGroup.get(this.lib.getId(this.ctrl?.VariavelNo));
      ctrl.setValue(this.imgIdoso);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'eventImgReturn', error.message);
    }
  }

  /* Clique no botão anexos. */
  onAttachment($event: any, isEditable: boolean): void {
    try {
      if (isEditable) {
        const ono = this.cnf.OcorrenciaNo.value;
        const uno = this.cnf.usuarioLogadoNo;
        const ano = this.cnf.ModeloAtividadeNo;
        const tno = -1;
        const vno = this.ctrl?.VariavelNo;
        // const url = this.cnfJson.attachmentUrl(
        //   ono,
        //   ano,
        //   uno,
        //   '60vh'
        // );
        const url = this.ctrl?.Rotulo;
        this.eventBubble
          .emit({
            $event,
            bubbleEvent: EnBubbleEvent.openAttachments,
            params: { ctrl: this.ctrl, url, ono, ano, uno, tno, vno, width: '100vw', height: '100vh', enFileUploadMode: this.fileUploadMode }
          } as IBubble);
      }
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'onAttachment', error.message);
    }
  }

  /*Clique no botão de enviar  */
  // openModalImage($event: any): void {
  //   try {
  //     const width = '80%';
  //     const height = '80%';
  //     const ocorrenciaNo = this.cnf.OcorrenciaNo.value;
  //     const atividadeNo = this.cnf.ModeloAtividadeNo;
  //     this.matDialog
  //       .open(FileUploadDialogComponent, {
  //         maxWidth: width,
  //         maxHeight: height,
  //         width,
  //         height,
  //         data: {
  //           ono: ocorrenciaNo,
  //           ano: atividadeNo,
  //           uno: this.cnf.usuarioLogadoNo,
  //           enFileUploadMode: EnFileUploadMode.single
  //         } as IFileUploadDialog
  //       })
  //       .afterClosed()
  //       .subscribe(result => {
  //         if (result) {
  //           this.eventImgReturn(result);
  //         }
  //       });
  //   } catch (error) {
  //     this.log.Registrar(this.constructor.name, 'openModalImage', error.message);
  //   }
  // }

  hasImage(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      if (this.global.isNullOrEmpty(ctrl?.Rotulo)) {
        return false;
      }
      const ext = this.global.extractFileNameFromPath(ctrl?.Rotulo).extension.replace('.', '');
      return this.extensions.includes(ext);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'hasImage', error.message);
    }
    return false;
  }

  isEmpty(ctrl: IAtividadeComponenteDAL): boolean {
    try {
      return this.global.isNullOrEmpty(ctrl?.Rotulo);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'isEmpty', error.message);
    }
    return false;
  }

  getDocName(fileName: string): string {
    try {
      return this.isMethod(fileName) ? '' : this.fileUploadSrv.getDocName(fileName);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'getDocName', error.message);
    }
    return null;
  }


}
