import { createReducer, on, Action } from '@ngrx/store';
import { loadProcesses, setProcess, fetchSuccessProcess, fetchErrorProcess, clearProcess } from './process.actions';
import { IProcessState } from '@medlogic/shared/shared-interfaces';

export const initialState: IProcessState = {
  selectedProcess: null,
  allProcesses: [],
  error: null
};

const processReducerObj = createReducer(initialState,
  on(loadProcesses, (state, payload) => ({
    ...state,
    bannerTitle: 'PROCESSOS',
    selectedProcess: null
  })),
  on(fetchSuccessProcess, (state, payload) => ({
    ...state, // FIXME: necessário, pois o retorno vazio está trazendo um array com um elemento null
    allProcesses: payload.allProcesses[0] ? payload.allProcesses : [],
    error: null
  })),
  on(setProcess, (state, payload) => ({
    ...state,
    selectedProcess: payload.selectedProcess,
    error: null
  })),
  on(fetchErrorProcess, (state, payload) => ({
    ...state,
    allProcesses: null,
    error: 'invalid-token'
  })),
  on(clearProcess, () => ({ ...initialState }))
);

export function processReducer(state: IProcessState | undefined, action: Action) {
  return processReducerObj(state, action);
}
