import { loadGeneric, loadGenericByPerson } from '@medlogic/shared/ui/ui-generic-list';
import { loadApp, setIsLoading } from '@medlogic/medlogic/medlogic-state';
import { ConfigJsonService, IProcessState, ITenant } from '@medlogic/shared/shared-interfaces';
import { IAppAddhereState, EnModulo, EnModuleMode, IModuleInfo } from '@medlogic/shared/shared-interfaces';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProcessActionTypes, fetchErrorProcess, fetchSuccessProcess, setProcess } from './process.actions';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { toArray } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { ProcessService } from '@medlogic/shared/shared-data-access';
import { LogService } from '@medlogic/shared/shared-interfaces';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { GlobalService } from '@medlogic/shared/shared-interfaces';
import { Store } from '@ngrx/store';
import { IModule, EnViewMode } from '@medlogic/shared/shared-interfaces';
import { ILogin } from '@medlogic/shared/shared-interfaces';

@Injectable()
export class ProcessEffects {

  // TODO: Falta acrescentar validação de permissões via acesso ao manager. Os processos estáticos, já com permissões, poderiam vir do serviço.
  private addCustomModules$ = (selectedTenant: ITenant & { cadPrescriptionNo: number }, login: ILogin) => map((allProcesses: IModule[]) => {
    let staticProcesses = [
      // {
      //   // UrlIcon: 'https://picsum.photos/68/68',
      //   UrlIcon: `${this.cnfJson.imgBasePath}/Arquivos/Icon/266.png`,
      //   Label: 'Núcleo de Cuidados',
      //   Type: EnModulo.Processo,
      //   ObjInfo: { AtividadeNo: null, ProcessoNo: 999, isCustom: true } as IModuleInfo,
      //   enModuleMode: EnModuleMode.list,
      //   IsActive: true,
      // } as IModule,
      {
        // UrlIcon: 'https://picsum.photos/68/68',
        UrlIcon: `${this.cnfJson.imgBasePath}/Arquivos/Icon/266.png`,
        Label: 'Prescrição',
        Type: EnModulo.Processo,
        ObjInfo: { AtividadeNo: null, ProcessoNo: 1000, isCustom: true } as IModuleInfo,
        enModuleMode: EnModuleMode.singleRecord,
        IsActive: true,
      } as IModule,
    ];
    staticProcesses = staticProcesses.filter(f => this.filterAuthorizedProcesses(f, login));
    this.fillDerivedFormId(staticProcesses, selectedTenant);
    return [...allProcesses, ...staticProcesses];
  });

  constructor(
    private cnfJson: ConfigJsonService,
    private glb: GlobalService,
    private log: LogService,
    private actions$: Actions,
    private store: Store<IAppAddhereState | any>,
    private processSrv: ProcessService // TODO: ContaService changed to the API
  ) { }

  loadProcess$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProcessActionTypes.LoadProcess),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.processSrv.getDataProcess(state?.tenant?.isDebug)
          .pipe(
            map(m => (m ? { ...m, Label: this.firstUpperCase(m?.Label) } : null)),
            toArray(),
            this.addCustomModules$(state?.tenant?.selectedTenant, state?.tenant?.login),
            switchMap((allProcesses: IModule[]) => [
              allProcesses ? fetchSuccessProcess({ allProcesses }) : fetchErrorProcess(null),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              this.store.dispatch(setIsLoading({ isLoading: false }));
              return of(fetchErrorProcess(null));
            })
          );
      })
    )
  );

  loadProcessesMenu$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProcessActionTypes.LoadProcessMenu),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.processSrv.getDataProcess(state?.tenant?.isDebug, 'MENU')
          .pipe(
            map(m => (m ? { ...m, Label: this.firstUpperCase(m?.Label) } : null)),
            toArray(),
            switchMap((allProcesses: IModule[]) => [
              allProcesses ? fetchSuccessProcess({ allProcesses }) : fetchErrorProcess(null),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              this.store.dispatch(setIsLoading({ isLoading: false }));
              return of(fetchErrorProcess(null));
            })
          );
      }),
      catchError((e: any) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(fetchErrorProcess(null));
      })
    )
  );

  loadProcessesHTML5$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProcessActionTypes.LoadProcessHTML5),
      withLatestFrom(this.store),
      mergeMap(([never, state]) => {
        return this.processSrv.getDataProcess(state?.tenant?.isDebug, 'HTML5')
          .pipe(
            map(m => (m ? { ...m, Label: this.firstUpperCase(m?.Label) } : null)),
            toArray(),
            this.addCustomModules$(state?.tenant?.selectedTenant, state?.tenant?.login),
            switchMap((allProcesses: IModule[]) => [
              allProcesses ? fetchSuccessProcess({ allProcesses }) : fetchErrorProcess(null),
              setIsLoading({ isLoading: false })
            ]),
            catchError((e: any) => {
              console.log(e);
              this.store.dispatch(setIsLoading({ isLoading: false }));
              return of(fetchErrorProcess(null));
            })
          );
      })
    )
  );

  setProcessAndLoadGeneric$ = createEffect(() => this.actions$
    .pipe(
      ofType(ProcessActionTypes.SetProcessAndLoadGeneric),
      switchMap((p: IProcessState) => {
        // const configModule = this.cnfJson.modules.find(f => f.isCustomerModule);
        const configModule = this.cnfJson.modules?.find(f => f.id === p.selectedProcess?.ObjInfo?.AtividadeNo);
        const identification = configModule?.identification || null;
        return [
          setProcess({ selectedProcess: p?.selectedProcess }),
          loadGeneric({ genericAno: p?.selectedProcess?.ObjInfo?.AtividadeNo, identification, genericLabel: p?.selectedProcess?.Label }),
          loadApp({ title: p?.selectedProcess?.Label, isLoading: true, enViewMode: EnViewMode.browse }),
          setIsLoading({ isLoading: false })
        ];
      }),
      catchError((e: any) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(fetchErrorProcess(null));
      })
    )
  );

  setProcessAndLoadGenericByPerson$ = createEffect(() => this.actions$
    .pipe(
      ofType<any>(ProcessActionTypes.SetProcessAndLoadGenericByPerson),
      withLatestFrom(this.store),
      switchMap(([action, state]) => {
        // TODO: Aqui não deveriam ser definidos parâmetros de identificação para ser possível saber quais as variáveis exibir nas listas?
        // const customerModule = this.cnfJson.modules.find(f => f.isCustomerModule);
        // const identification = customerModule?.identification || null;
        const p = action?.selectedProcess;
        return [
          setProcess({ selectedProcess: p }),
          loadGenericByPerson({ genericAno: p?.ObjInfo?.AtividadeNo, genericLabel: p?.Label, person: state?.person?.person }),
          loadApp({ title: p?.Label, isLoading: true, enViewMode: EnViewMode.browse }),
          setIsLoading({ isLoading: false })
        ];
      }),
      catchError((e: any) => {
        console.log(e);
        this.store.dispatch(setIsLoading({ isLoading: false }));
        return of(fetchErrorProcess(null));
      })
    )
  );
  private filterAuthorizedProcesses(process: IModule, login: ILogin): boolean {
    try {
      const module = this.cnfJson.modules?.find(f => f.id === process.ObjInfo.ProcessoNo);
      return login?.roles?.findIndex(role =>
        module?.roles?.map(m => m.toUpperCase()).includes(role.toUpperCase())
      ) >= 0;
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'filterAuthorizedProcess', error.message);
    }
    return false;
  }

  /* Como é módulo personalizado, extrairá o id da atividade conformed especificado no Cadastro do Idoso Bem Cuidado, campo cadPrescriptionNo. */
  private fillDerivedFormId(staticProcesses: IModule[], selectedTenant: ITenant & { cadPrescriptionNo: number }): void {
    try {
      const modules = this.cnfJson.modules;
      // const derivedForm = (pno: number) => modules
      //   .find(f => this.glb.isEqual(
      //     f.name,
      //     modules.find(f2 => f2.id === pno)?.derivedFormName)
      //   );
      const getModuleCadNoName = (pno: number) => modules?.find(f => f.id === pno)?.cadNoName;
      staticProcesses.forEach(e => {
        const cadNoName = getModuleCadNoName(e.ObjInfo?.ProcessoNo);
        if (cadNoName) {
          e.ObjInfo.AtividadeNo = selectedTenant[cadNoName] || null;
        } else {
          alert(`cadNoName não configurado no config.json para o processo: ${e.ObjInfo.ProcessoNo}`);
        }
      });
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'fillDerivedFormId', error.message);
    }
  }

  protected firstUpperCase(str: string): string {
    try {
      const siglas = ['SIGLA'];
      if (siglas.includes(str.toUpperCase())) {
        return str.toUpperCase();
      }
      return this.glb.primeiraMaiuscula(str);
    } catch (error) {
      this.log.Registrar(this.constructor.name, 'firstUpperCase', error.message);
    }
    return str;
  }


}
